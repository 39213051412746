import React, { createContext, useState, useEffect, useContext } from 'react';
import { createUser, signInUser } from '../../services/authService';

// Create a context for user authentication
const AuthContext = createContext();

// Custom hook to access the user context
export const useAuth = () => useContext(AuthContext);

// User provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to sign up a new user
  const signup = async (username, email, password) => {
    try {
      const user = await createUser(username, email, password);
      setCurrentUser(user);
    } catch (error) {
      throw error;
    }
  };

  // Function to log in a user
  const login = async (identifier, password) => {
    try {
      const result = await signInUser(identifier, password);
      setCurrentUser(result);
    } catch (error) {
      console.error('Error logging in:', error.message);
      throw error;
    }
  };

  // Function to log out the current user
  const logout = () => {
    // Implement logout logic if needed
    setCurrentUser(null);
  };

  // Listen for authentication state changes
  useEffect(() => {
    // Implement logic to check current user if needed
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, signup, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};