import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import EnclosureCard from './EnclosureCard';
import './enclosures.scss';

const EnclosureGuideBanner = () => {
  const { t } = useTranslation();
  const enclosureGuideList = [
    {
      enclosureLevel: 'beginner',
      enclosureType: t('Enclosure.Types.Beginner.Name'),
      enclosureDescription: t('Enclosure.Types.Beginner.Description'),
      image: 'https://m.media-amazon.com/images/I/719xJNCgO5L.jpg'
    },
    {
      enclosureLevel: 'intermediate',
      enclosureType: t('Enclosure.Types.Intermediate.Name'),
      enclosureDescription: t('Enclosure.Types.Intermediate.Description'),
      image: 'https://i.pinimg.com/originals/25/90/49/259049e8fcff0654d80087476c89db4d.jpg'
    },
    {
      enclosureLevel: 'expert',
      enclosureType: t('Enclosure.Types.Expert.Name'),
      enclosureDescription: t('Enclosure.Types.Expert.Description'),
      image: 'https://i.pinimg.com/originals/ce/00/44/ce00446ec60e0ddae762522e0ed629a7.jpg'
    }
  ]

  return (
    <div className="enclosureGuideBanner">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} className="guide-column-text">
          <Typography variant="h5" gutterBottom>
            {t('Enclosure.Guides')}
          </Typography>
          <Typography variant="body1">
            {t('Enclosure.Description')}
          </Typography>
        </Grid>

        {enclosureGuideList.map((enclosure, index) => (
          <Grid item xs={12} sm={6} md={3} className="guide-column" key={index}>
            <EnclosureCard
              enclosureLevel={enclosure.enclosureLevel}
              enclosureType={enclosure.enclosureType}
              enclosureDescription={enclosure.enclosureDescription}
              image={enclosure.image}
              className="enclosure-card"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default EnclosureGuideBanner;
