import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';

import translationsEn from './utils/locales/en.json';
import translationsFr from './utils/locales/fr.json';
import translationsEs from './utils/locales/es.json';

i18n.init({
  resources: {
    en: {
      translation: translationsEn,
    },
    fr: {
      translation: translationsFr,
    },
    es: {
      translation: translationsEs,
    },
  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
);
