import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';

function EnclosureCard(props) {
  return (
    <Link to={`/guides/${props.enclosureLevel}`} style={{ textDecoration: 'none' }}>
      <Card className="enclosure-card">
        <CardMedia
          component="img"
          height="240"
          image={props.image}
          alt={props.enclosureType}
        />
        <CardContent>
          <Typography variant="h6">{props.enclosureType}</Typography>
          <Typography variant="body2">
            {props.enclosureDescription}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  )
}

EnclosureCard.propTypes = {
  enclosureLevel: PropTypes.string,
  enclosureType: PropTypes.string,
  enclosureDescription: PropTypes.string,
  image: PropTypes.string
}

export default EnclosureCard
