import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const DiscoverLanding = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Ready to find the right reptile for you?
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/quiz">
        Start Reptile Quiz
      </Button>
      <Typography variant="h5" style={{ marginTop: '50px' }}>
        Have a reptile but no home for it?
      </Typography>
      <Button variant="contained" color="secondary" component={Link} to="/build">
        Head to the Enclosure Builder
      </Button>
    </div>
  );
};

export default DiscoverLanding;
