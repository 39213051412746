import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/context/AuthContext';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './views/HomeView';
import About from './views/AboutView';
import Profile from './views/ProfileView';
import Build from './views/BuildView';
import Discover from './views/DiscoverView';
import Quiz from './views/QuizView';
import Reptiles from './views/ReptilesView';
import Guide from './views/GuideView';
import NotFound from './views/NotFound';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <Navbar />
              <Box component="main" sx={{ flexGrow: 1 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/build" element={<Build />} />
                <Route path="/discover" element={<Discover />} />
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/reptiles/*" element={<Reptiles />} />
                <Route path="/guides/*" element={<Guide />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
