import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { getReptileById } from '../../services/reptileService';
import './featured.scss';

const Featured = () => {
  const [featuredReptile, setFeaturedReptile] = useState({});

  useEffect(() => {
    const fetchFeaturedReptile = async () => {
      try {
        const response = await getReptileById('9'); // Hardcoded for now, this returns data for Amazon Milk Frog
        const capitalizedType = response.type.charAt(0).toUpperCase() + response.type.slice(1);
        setFeaturedReptile({ ...response, type: capitalizedType });
      } catch (error) {
        console.error(error);
      }
    };
    fetchFeaturedReptile();
  }, []);

  return (
    <div className='featured'>
      <Typography variant="h4" gutterBottom>
        Today's Featured Reptile
      </Typography>
      <Grid container spacing={2} className='featured-container'>
        <Grid item xs={12} md={4} style={{ padding: '5px' }}>
          <div className='featured-container-left'>
            <div className='featured-image'>
              <img
                src={featuredReptile.image}
                alt={featuredReptile.commonName}
                style={{ width: '100%' }}
              />
            </div>
            <Typography variant="h5" gutterBottom>
              {featuredReptile.commonName}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={8} style={{ padding: '5px' }}>
          <Typography variant="h6" gutterBottom>
            Type: {featuredReptile.type}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Native Region: {featuredReptile.nativeHabitat}
          </Typography>
          <Typography variant="body1">
            {featuredReptile.description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Featured;
