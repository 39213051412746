import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Button, TextField, Modal, Box, Typography } from '@mui/material';

const Signin = ({ isOpen, onClose, onSignUpClick }) => {
  const { login } = useAuth();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setIdentifier('');
    setPassword('');
    setError('');
    setLoading(false);
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await login(identifier, password);
      onClose();
      window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 500, bgcolor: 'background.paper', p: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Login
        </Typography>
        <form onSubmit={handleSignIn}>
          <TextField
            type="text"
            label="Username or Email"
            variant="outlined"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !identifier || !password}
            fullWidth
          >
            Login
          </Button>
        </form>
        <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
          Don't have an account? <span onClick={onSignUpClick} style={{ cursor: 'pointer', color: 'blue' }}>Sign up</span>
        </Typography>
      </Box>
    </Modal>
  );
};

export default Signin;
