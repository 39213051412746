import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import './reptiles.scss';

const ReptileListItem = ({ condensed, reptile }) => {
  return (
    <div className='reptileListItem'>
      <Link to={`/reptiles/${reptile.type}s/${reptile.PK.split('#')[1]}`} style={{ textDecoration: 'none' }}>
        {!condensed ? (
          <Card style={{ cursor: 'pointer' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CardMedia
                  component="img"
                  image={reptile.image}
                  alt={reptile.commonName}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardContent>
                  <div className='reptileTitleRow'>
                    <Typography variant="h4">{reptile.commonName}</Typography>
                    {reptile.verified && (
                      <Tooltip title="Verified Information">
                        <VerifiedIcon style={{ color: 'green', marginLeft: '10px', fontSize: "30px" }} />
                      </Tooltip>
                    )}
                  </div>
                  <Typography variant="body1">{reptile.species}</Typography>
                  <Typography variant="body2">{reptile.nativeHabitat}</Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <CardMedia
                    component="img"
                    image={reptile.image}
                    alt={reptile.commonName}
                    style={{ height: '100px', width: '100px', marginRight: '10px', objectFit: 'cover'}}
                  />
                </Grid>
                <Grid item>
                  <div className='reptileTitleRow'>
                    <Typography variant="h5">{reptile.commonName}</Typography>
                    {reptile.verified && (
                      <Tooltip title="Verified Information">
                        <VerifiedIcon style={{ color: 'green', marginLeft: '5px' }} />
                      </Tooltip>
                    )}
                  </div>
                  <Typography variant="body2">{reptile.species}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Link>
    </div>
  );
}

export default ReptileListItem;