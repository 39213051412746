import React, { useState, useEffect } from 'react';
import { Button, Container, Typography, IconButton, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getEnclosures } from '../../services/enclosureService';
import { EnclosureItemModal } from './EnclosureItemModal';
import { EnclosureItem } from './EnclosureItem';
import './build.scss';

const EnclosureBuilder = () => {
  const [loading, setLoading] = useState(true);
  const [parts, setParts] = useState({
    enclosures: [],
    plants: [],
    substrate: [],
    lights: [],
    water: [],
    materials: [],
  });

  useEffect(() => {
    setLoading(true);
    const fetchEnclosureParts = async () => {
      try {
        const response = await getEnclosures();
        setParts({ ...parts, enclosures: response });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchEnclosureParts();
  }, [parts]);

  const addToParts = (section, item) => {
    const updatedParts = { ...parts };
    updatedParts[section].push(item);
    setParts(updatedParts);
  };

  const removeFromParts = (section, index) => {
    const updatedParts = { ...parts };
    updatedParts[section].splice(index, 1);
    setParts(updatedParts);
  };

  const [totalCost] = useState(0);
  const reptiles = ['Bearded Dragon', 'Leopard Gecko', 'Ball Python', 'Corn Snake']; // dummy data
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBuyNow = () => {
    // Handle buy now action
    console.log('Items:', parts);
    console.log('Total Cost:', totalCost);
  };

  return (
    <Container>
      <div className='enclosure-builder-header'>
        <Typography variant="h4" gutterBottom>
          Enclosure Build Page
        </Typography>
      </div>
      <div>
        <Typography variant="h5">
          What reptile are you building for?
        </Typography>
        <Select>
          {reptiles.map((reptile, index) => (
            <MenuItem key={index} value={reptile}>
              {reptile}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Enclosure
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
          onClick={openModal}
        >
          <AddIcon style={{ fontSize: 15 }} /> 
        </Button>
        {parts.enclosures.map((enclosure, index) => (
          <div>
            <EnclosureItem key={index} item={enclosure} />
            <IconButton onClick={() => removeFromParts('enclosures', index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <EnclosureItemModal
          isOpen={isModalOpen}
          onClose={closeModal}
          category="enclosures"
          items={parts.enclosures}
          addToParts={addToParts}
          loading={loading}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Lights
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
        >
          <AddIcon style={{ fontSize: 15 }} /> 
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Plants
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
        >
          <AddIcon style={{ fontSize: 15 }} />
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Substrate
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
        >
          <AddIcon style={{ fontSize: 15 }} />
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Materials
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
        >
          <AddIcon style={{ fontSize: 15 }} /> 
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50 }}>
        <Typography variant="h5">
          Water Feature
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{
            minWidth: 25,
            height: 25,
            borderRadius: '50%',
            padding: 0,
            margin: 5,
          }}
        >
          <AddIcon style={{ fontSize: 15 }} /> 
        </Button>
      </div>
      {/* Repeat similar structure for Lights, Water Feature, Enclosure */}
      <div style={{ textAlign: 'right', alignContent: 'center' }}>
        <Typography variant="h6">
          Total Cost: ${totalCost}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleBuyNow}>
          Buy Now
        </Button>
      </div>
    </Container>
  );
};

export default EnclosureBuilder;
