import React from 'react';
import Banner from '../components/banner/Banner';
import CommunityBanner from '../components/banner/CommunityBanner';
import EnclosureGuideBanner from '../components/enclosures/EnclosureGuideBanner';
import Featured from '../components/featured/Featured';

const HomeView = () => {
  return (
    <div>
      <Banner />
      <CommunityBanner />
      <EnclosureGuideBanner />
      <Featured />
    </div>
  );
};

export default HomeView;
