import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { GiSandSnake, GiFrog, GiTurtle, GiReptileTail } from "react-icons/gi";

const ReptilesLanding = () => {
  return (
    <div className='reptileLandingPage'>
      <Typography variant="h2" gutterBottom>
        Reptiles
      </Typography>
      <Grid container className='reptileLandingPageContainer'>
        <Grid item xs={12} sm={6} className='reptileLandingPageItem'>
          <Link to="lizards" style={{ textDecoration: 'none' }}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <GiReptileTail size={100} />
              <Typography variant="h6">
                Lizards
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} className='reptileLandingPageItem'>
          <Link to="snakes" style={{ textDecoration: 'none' }}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <GiSandSnake size={100} />
              <Typography variant="h6">
                Snakes
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} className='reptileLandingPageItem'>
          <Link to="turtles" style={{ textDecoration: 'none' }}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <GiTurtle size={100} />
              <Typography variant="h6">
                Turtles
              </Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} className='reptileLandingPageItem'>
          <Link to="amphibians" style={{ textDecoration: 'none' }}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <GiFrog size={100} />
              <Typography variant="h6">
                Amphibians
              </Typography>
            </Paper>
          </Link>
        </Grid>
      </Grid>
      <Outlet />
    </div>
  );
};

export default ReptilesLanding;
