import { createTheme } from '@mui/material/styles';

const colours = {
  1: '#004e64',
  2: '#00a5cf',
  3: '#9fffcb',
  4: '#25a18e',
  5: '#7ae582',
}

const theme = createTheme({
  palette: {
    primary: {
      main: colours[4],
    },
    secondary: {
      main: colours[3],
    },
  },
});

export default theme;