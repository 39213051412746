import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { getReptileById } from '../../services/reptileService';

const ReptileDetails = () => {
  const { id } = useParams();
  const [reptile, setReptile] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchReptile = async () => {
      try {
        const response = await getReptileById(id);
        setReptile(response);
      } catch (error) {
        setError('Error fetching reptile details');
      } finally {
        setLoading(false);
      }
    };
    fetchReptile();
  }, [id]);

  if (loading) return <CircularProgress />;

  if (error) return <Typography variant="h4">{error}</Typography>;

  return (
    <div>
      { reptile ? (
        <>
          <Typography variant="h4">{reptile.commonName}</Typography>
          <img src={reptile.image} alt={reptile.commonName} />
          <Typography variant="h6">{reptile.species}</Typography>
          <Typography variant="body1">{reptile.description}</Typography>
          <Typography variant="body2">Native Habitat: {reptile.nativeHabitat}</Typography>
          <Typography variant="body2">Lifespan: {reptile.lifespan[0]}-{reptile.lifespan[1]} years</Typography>
        </>
      ) : (
        <Typography variant="h3">Reptile not found</Typography>
      )}
      
    </div>
  );
}

export default ReptileDetails;
