import api from './api';

export const getReptiles = async () => {
  try {
    const response = await api.get('/reptiles');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reptiles: ${error.message}`);
  }
}

export const getReptilesByType = async (type) => {
  try {
    const response = await api.get(`/reptiles/${type}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reptiles by type: ${error.message}`);
  }
}

export const getReptileById = async (id) => {
  try {
    const response = await api.get(`/reptiles/id/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reptile by id: ${error.message}`);
  }
}