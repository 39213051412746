import React from 'react';
import About from '../components/about/About.jsx';

const AboutView = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutView;