import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReptilesLanding from '../components/reptiles/ReptilesLanding';
import ReptileList from '../components/reptiles/ReptileList';
import ReptileDetails from '../components/reptiles/ReptileDetails';

const ReptilesView = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ReptilesLanding />} />
          <Route path="lizards" element={<ReptileList type="lizard" />} />
          <Route path="lizards/:id" element={<ReptileDetails />} />
          <Route path="snakes" element={<ReptileList type="snake" />} />
          <Route path="snakes/:id" element={<ReptileDetails />} />
          <Route path="turtles" element={<ReptileList type="turtle" />} />
          <Route path="turtles/:id" element={<ReptileDetails />} />
          <Route path="amphibians" element={<ReptileList type="amphibian" />} />
          <Route path="amphibians/:id" element={<ReptileDetails />} />
      </Routes>
    </>
  );
}

export default ReptilesView;
