import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './footer.scss';

const Footer = () => {
  const theme = useTheme();

  const footerStyle = {
    backgroundColor: theme.palette.primary.main,
  };

  return (
    <footer className="footer" style={footerStyle}>
      <Container maxWidth="lg">
        <Grid container spacing={4} className='footerContainer'>
          <Grid item xs={6} sm={4}>
            <Typography variant="h6" gutterBottom>Navigation</Typography>
            <ul className="footerNav">
              <li><Link href="/" color="inherit">Home</Link></li>
              <li><Link href="/about" color="inherit">About Us</Link></li>
              <li><Link href="/build" color="inherit">Build an Enclosure</Link></li>
              <li><Link href="/discover" color="inherit">Discover Reptiles</Link></li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4}>
            <ul className='footerNav'>
              <Typography variant="h6" gutterBottom>Reptiles</Typography>
              <li><Link href="/reptiles" color="inherit">All Reptiles</Link></li>
              <li><Link href="/reptiles/snakes" color="inherit">Snakes</Link></li>
              <li><Link href="/reptiles/lizards" color="inherit">Lizards</Link></li>
              <li><Link href="/reptiles/turtles" color="inherit">Turtles</Link></li>
              <li><Link href="/reptiles/amphibians" color="inherit">Amphibians</Link></li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>Contact Us</Typography>
            <Typography variant="body2" color="textSecondary">
              contact@reptibuddy.com
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="footerBottom">
          <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}
            {' ReptiBuddy. All rights reserved.'}
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
