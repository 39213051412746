import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Drawer, List, ListItemText, ListItemButton, Avatar, Snackbar, Alert } from '@mui/material';
import { IoMdWarning } from "react-icons/io";
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../context/AuthContext';
import Signin from '../auth/Signin';
import Signup from '../auth/Signup';
import './navbar.scss';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const { currentUser, logout } = useAuth();

  const featureFlags = {
    underConstruction: true,
    about: true,
    build: true,
    discover: true,
    reptiles: true,
    forums: false,
    user: true,
    language: false
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [reptilesAnchorEl, setReptilesAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSignupSuccess, setShowSignupSuccess] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReptilesMenuClick = (event) => {
    setReptilesAnchorEl(event.currentTarget);
  };

  const handleReptilesMenuClose = () => {
    setReptilesAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setAnchorEl(null);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const openRegisterModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleSignupSuccess = () => {
    setShowSignupSuccess(true);
    setIsLoginModalOpen(true);
  };

  const handleCloseSnackbar = () => {
    setShowSignupSuccess(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const drawerList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        { featureFlags.about && (
          <ListItemButton component={Link} to="/about">
            <ListItemText primary={t('About')} />
          </ListItemButton>
        )}
        { featureFlags.build && (
          <ListItemButton component={Link} to="/build">
            <ListItemText primary={t('Build')} />
          </ListItemButton>
        )}
        { featureFlags.discover && (
          <ListItemButton component={Link} to="/discover">
            <ListItemText primary={t('Discover')} />
          </ListItemButton>
        )}
        { featureFlags.reptiles && (
          <>
            <ListItemButton onClick={handleReptilesMenuClick}>
              <ListItemText primary={t('Reptiles')} />
              <ExpandMoreIcon />
            </ListItemButton>
            <Menu
              id="reptiles-menu"
              anchorEl={reptilesAnchorEl}
              keepMounted
              open={Boolean(reptilesAnchorEl)}
              onClose={handleReptilesMenuClose}
            >
              <MenuItem component={Link} to="/reptiles/lizards">
                {t('Lizards')}
              </MenuItem>
              <MenuItem component={Link} to="/reptiles/snakes">
                {t('Snakes')}
              </MenuItem>
              <MenuItem component={Link} to="/reptiles/turtles">
                {t('Turtles')}
              </MenuItem>
              <MenuItem component={Link} to="/reptiles/amphibians">
                {t('Amphibians')}
              </MenuItem>
            </Menu>
          </>
        )}
        {featureFlags.forums && (
          <ListItemButton>
            <ListItemText primary={t('Forums')} />
          </ListItemButton>
        )}
        { featureFlags.user && (
          <>
            {!currentUser ? (
              <>
                <ListItemButton onClick={openLoginModal}>
                  <ListItemText primary={t('Login')} />
                </ListItemButton>
                <ListItemButton onClick={openRegisterModal}>
                  <ListItemText primary={t('Register')} />
                </ListItemButton>
              </>
            ) : (
              <>
                <ListItemButton component={Link} to="/profile">
                  <ListItemText primary={t('Profile')} />
                </ListItemButton>
                <ListItemButton onClick={handleLogout}>
                  <ListItemText primary={t('Logout')} />
                </ListItemButton>
              </>
            )}
          </>
        )}
        { featureFlags.language && (
          <>
            <ListItemButton onClick={handleMenuClick}>
              <ListItemText primary={t('Language')} />
              <ExpandMoreIcon />
            </ListItemButton>
            <Menu
              className="languageDropdown"
              id="language-dropdown"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleLanguageSelect('en')}>
                <img src="https://flagicons.lipis.dev/flags/4x3/us.svg" alt="en" />
                EN
              </MenuItem>
              <MenuItem onClick={() => handleLanguageSelect('fr')}>
                <img src="https://flagicons.lipis.dev/flags/4x3/fr.svg" alt="fr" />
                FR
              </MenuItem>
              <MenuItem onClick={() => handleLanguageSelect('es')}>
                <img src="https://flagicons.lipis.dev/flags/4x3/es.svg" alt="es" />
                ES
              </MenuItem>
            </Menu>
          </>
        )}
      </List>
    </div>
  );

  const UnderConstruction = () => (
    <div className="underConstruction">
      <div className='desktop'>
        <p>
          <IoMdWarning/>Site Under Construction - Expect Incomplete Content / Constant Updates!<IoMdWarning/>
        </p>
      </div>
      <div className='mobile'>
        <p>
          <IoMdWarning/>Site Under Construction<IoMdWarning/>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <AppBar position="static" className="appBar" sx={{ alignItems: 'center' }}>
        <Toolbar sx={{ maxWidth: '1200px', width: '100%' }}>
          <div className="desktop-menu">
            <div className="logo">
              <Link to="/">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/8158/8158529.png"
                  alt="Logo"
                />
              </Link>
            </div>
          
            <Typography variant="h6" component="div">
              { featureFlags.about && ( <Button component={Link} to="/about" color="inherit">{t('About')}</Button> )}
              { featureFlags.build && ( <Button component={Link} to="/build" color="inherit">{t('Build')}</Button> )}
              { featureFlags.discover && ( <Button component={Link} to="/discover" color="inherit">{t('Discover')}</Button> )}
              { featureFlags.reptiles && (
                <>
                  <Button
                    color="inherit"
                    aria-controls="reptiles-menu"
                    aria-haspopup="true"
                    onClick={handleReptilesMenuClick}
                  >
                    {t('Reptiles')} <ExpandMoreIcon />
                  </Button>
                  <Menu
                    id="reptiles-menu"
                    anchorEl={reptilesAnchorEl}
                    keepMounted
                    open={Boolean(reptilesAnchorEl)}
                    onClose={handleReptilesMenuClose}
                  >
                    <MenuItem component={Link} to="/reptiles/lizards">
                      {t('Lizards')}
                    </MenuItem>
                    <MenuItem component={Link} to="/reptiles/snakes">
                      {t('Snakes')}
                    </MenuItem>
                    <MenuItem component={Link} to="/reptiles/turtles">
                      {t('Turtles')}
                    </MenuItem>
                    <MenuItem component={Link} to="/reptiles/amphibians">
                      {t('Amphibians')}
                    </MenuItem>
                  </Menu>
                </>
              )}
              { featureFlags.forums && ( <Button color="inherit">{t('Forums')}</Button> )}
            </Typography>
            <div className="grow" />
            { featureFlags.user && (
              <>
                {!currentUser ? (
                  <div className="userMenu">
                    <Button onClick={openLoginModal} color="inherit">{t('Login')}</Button>
                    <Button onClick={openRegisterModal} color="inherit">{t('Register')}</Button>
                  </div>
                ) : (
                  <div className="userMenu">
                    <Avatar component={Link} to="/profile" alt={currentUser.email} src={currentUser.photoURL} sx={{ width: 36, height: 36, marginRight: '10px' }}/>
                    <Button onClick={handleLogout} color="inherit">{t('Logout')}</Button>
                  </div>
                )}
                <Signin 
                  isOpen={isLoginModalOpen}
                  onClose={() => setIsLoginModalOpen(false)}
                  onSignUpClick={() => {
                    setIsLoginModalOpen(false);
                    setIsSignupModalOpen(true);
                  }}
                />
                <Signup
                  isOpen={isSignupModalOpen}
                  onClose={() => setIsSignupModalOpen(false)}
                  onLoginClick={() => {
                    setIsSignupModalOpen(false);
                    setIsLoginModalOpen(true);
                  }}
                  onSignupSuccess={handleSignupSuccess}
                />
              </>
            )}

            { featureFlags.language && (
              <div className='languageMenu'>
                <Button
                  className='languageButton'
                  aria-controls="language-dropdown"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  color="inherit"
                >
                  {/* Language flag icon based on the selected language */}
                  {selectedLanguage === 'en' && (
                    <img
                      src="https://flagicons.lipis.dev/flags/4x3/us.svg"
                      alt="English"
                    />
                  )}
                  {selectedLanguage === 'fr' && (
                    <img
                      src="https://flagicons.lipis.dev/flags/4x3/fr.svg"
                      alt="French"
                    />
                  )}
                  {selectedLanguage === 'es' && (
                    <img
                      src="https://flagicons.lipis.dev/flags/4x3/es.svg"
                      alt="Spanish"
                    />
                  )}
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  className="languageDropdown"
                  id="language-dropdown"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleLanguageSelect('en')}>
                    <img src="https://flagicons.lipis.dev/flags/4x3/us.svg" alt="en" />
                    EN
                  </MenuItem>
                  <MenuItem onClick={() => handleLanguageSelect('fr')}>
                    <img src="https://flagicons.lipis.dev/flags/4x3/fr.svg" alt="fr" />
                    FR
                  </MenuItem>
                  <MenuItem onClick={() => handleLanguageSelect('es')}>
                    <img src="https://flagicons.lipis.dev/flags/4x3/es.svg" alt="es" />
                    ES
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>

          <div className="mobile-menu">
            <Typography variant="h6" component="div">
              <Link to="/" className="logo">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/8158/8158529.png"
                  alt="Logo"
                />
              </Link>
            </Typography>
            <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)} style={{ marginRight: '5px' }}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerList()}
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
      <Snackbar
        open={showSignupSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Sign up successful. Verify email.
        </Alert>
      </Snackbar>

      { featureFlags.underConstruction && <UnderConstruction /> }
    </>
  );
};

export default NavBar;
