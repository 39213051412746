import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import './about.scss';

const About = () => {
  return (
    <Container className="about-us" maxWidth="md">
      <Paper elevation={3} className="about-us__paper">
        <Typography variant="h3" gutterBottom>About ReptiBuddy</Typography>
        <Typography variant="body1" paragraph>
          ReptiBuddy was created out of a passion for reptiles and a desire to build a community where enthusiasts can come together to share knowledge, experiences, and best practices. Our goal is to provide the most accurate and up-to-date information about reptile care, ensuring that every reptile owner can provide the best possible environment for their scaly friends.
        </Typography>
        <Typography variant="h4" gutterBottom>Our Mission</Typography>
        <Typography variant="body1" paragraph>
          At ReptiBuddy, we strive to:
        </Typography>
        <ul className="about-us__list">
          <li>Offer comprehensive and reliable care information for various reptile species.</li>
          <li>Build a supportive and knowledgeable community of reptile enthusiasts.</li>
          <li>Ensure the well-being and health of reptiles through education and resources.</li>
        </ul>
        <Typography variant="h4" gutterBottom>Community Driven</Typography>
        <Typography variant="body1" paragraph>
          ReptiBuddy is driven by its community. Our platform thrives on the contributions of reptile owners, experts, and hobbyists who share their insights and experiences. We believe in the power of community to foster learning and growth, making reptile care accessible to everyone.
        </Typography>
        <Typography variant="h4" gutterBottom>Meet the Team</Typography>
        <Typography variant="body1" paragraph>
          Our team is composed of dedicated reptile enthusiasts and experts who are passionate about animal welfare and education. We work tirelessly to gather, verify, and present the best information available. Our commitment is to ensure that ReptiBuddy remains a trusted resource for all your reptile care needs.
        </Typography>
        <Typography variant="body1" paragraph>
          Join us in our mission to create a world where reptiles are understood, appreciated, and cared for with the utmost respect and knowledge.
        </Typography>
      </Paper>
    </Container>
  );
};

export default About;
