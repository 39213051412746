import React from 'react';
import DiscoverLanding from '../components/discover/DiscoverLanding';

const DiscoverView = () => {
  return (
    <div>
      <DiscoverLanding />
    </div>
  );
};

export default DiscoverView;