import React, { useState, useEffect } from 'react';
import { getReptilesByType } from '../../services/reptileService';
import { Grid, Typography, TextField, CircularProgress } from '@mui/material';
import ReptileListItem from './ReptileListItem';
import './reptiles.scss';

const ReptileList = ({ type }) => {
  const [reptiles, setReptiles] = useState([]);
  const [search, setSearch] = useState('');
  const [mobileScreen, setMobileScreen] = useState(window.innerWidth < 960);
  const [loading, setLoading] = useState(true);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    window.matchMedia('(max-width: 960px)').addEventListener('change', (event) => {
      setMobileScreen(event.matches);
    });
  }, []);

  const filteredReptiles = reptiles.filter(reptile =>
    reptile.commonName.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    setReptiles([]);
    const fetchReptiles = async (type) => {
      try {
        const response = await getReptilesByType(type);
        setReptiles(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchReptiles(type);
  }, [type]);

  if (loading) return <CircularProgress />;

  return (
    <div className='reptileList'>
      { !mobileScreen && (
        <>
          <Typography variant="h3" style={{marginBottom: '25px'}}>{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
          <Grid container spacing={2}>
            {reptiles.slice(0,4).map(reptile => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={reptile.PK.split('#')[1]}>
                <ReptileListItem reptile={reptile} />
              </Grid>
            ))}
          </Grid>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            style={{ margin: '20px 0' }}
          />
          <Grid container spacing={2}>
            {filteredReptiles.slice(4).map(reptile => (
              <Grid item xs={12} key={reptile.PK.split('#')[1]}>
                <ReptileListItem condensed={true} reptile={reptile} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      { mobileScreen && (
        <>
          <Typography variant="h3">{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            style={{ margin: '20px 0' }}
          />
          <Grid container spacing={2}>
            {filteredReptiles.map(reptile => (
              <Grid item xs={12} key={reptile.PK.split('#')[1]}>
                <ReptileListItem condensed={true} reptile={reptile} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

export default ReptileList;