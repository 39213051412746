import React from 'react';
import Quiz from '../components/discover/Quiz';

const QuizView = () => {
  return (
    <div>
      <Quiz />
    </div>
  );
}

export default QuizView;
