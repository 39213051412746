import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Button, TextField, Modal, Box, Typography, Alert } from '@mui/material';

const Signup = ({ isOpen, onClose, onLoginClick, onSignupSuccess }) => {
  const { signup } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setError('');
    setLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await signup(username, email, password);
      onSignupSuccess();
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 500, bgcolor: 'background.paper', p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Register
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              type="text"
              placeholder="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              type="email"
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              type="password"
              placeholder="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !username || !email || !password}
              fullWidth
            >
              Sign up
            </Button>
          </form>
          <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
            Already have an account? <span onClick={onLoginClick} style={{ color: 'blue', cursor: 'pointer' }}>Sign In</span>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Signup;