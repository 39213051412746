import api from './api';

export const getProfileByUserId = async (userId) => {
  try {
    const response = await api.get(`/user/profile`, {
      headers: {
        Authorization: userId,
      }  
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching profile by user ID: ${error.message}`);
  }
}