import React from 'react';
import { Paper, Typography } from '@mui/material';
import './banner.scss';

const CommunityBanner = () => {
  return (
    <Paper className='communityBanner'>
      <Typography variant="h4">
        Welcome to ReptiBuddy!
      </Typography>
      <Typography variant="h5">
        Created by Reptile Lovers, For Reptile Lovers
      </Typography>
      <div className='communityBannerText'>
        <Typography variant="body1">
          Welcome to ReptiBuddy! We are a community of reptile enthusiasts who are passionate about providing the best care for our scaly friends.
          Whether you are a seasoned reptile owner or just starting out, we are here to help you every step of the way. Join our community today!
        </Typography>
        <Typography variant="body1">
          We are always looking to keep our information up-to-date. If you have any suggestions or feedback, please feel free to reach out to us below. Any and all feedback is appreciated!
        </Typography>
      </div>
    </Paper>
  );
}

export default CommunityBanner;