import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getQuiz } from '../../services/quizService';
import { Button, Typography, Grid } from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import './discover.scss';

const Quiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizQuestionsAndOptions, setQuizQuestionsAndOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const response = await getQuiz();
        response.sort((a, b) => a.index - b.index);
        setQuizQuestionsAndOptions(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuizQuestions();
  }, []);

  const handleAnswer = (answer) => {
    setAnswers(prevAnswers => [...prevAnswers, answer]);
    if (currentQuestionIndex < quizQuestionsAndOptions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      // Quiz completed, determine best fit reptile
      determineBestFitReptile();
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleClose = () => {
    navigate('/discover');
  };

  const determineBestFitReptile = () => {
    // Logic to determine best fit reptile based on answers
    console.log('Answers:', answers);
  };

  return (
    <div className='quizContainer'>
      <div className='quizNavButtons'>
        <Button variant="outlined" color="primary" startIcon={<ArrowBack />} onClick={handleBack} />
        <Button variant="outlined" color="primary" startIcon={<Close />} onClick={handleClose} />
      </div>
      <Typography variant="h4" gutterBottom>
        Reptile Quiz
      </Typography>
      {currentQuestionIndex < quizQuestionsAndOptions.length && (
        <div>
          <Typography variant="h6" gutterBottom>
            Question {currentQuestionIndex + 1}:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {quizQuestionsAndOptions[currentQuestionIndex].question}
          </Typography>
          <div className='quizButtons'>
            {quizQuestionsAndOptions[currentQuestionIndex].options.map((option, index) => (
              <Button
                key={index}
                variant="contained"
                color="primary"
                onClick={() => handleAnswer(option)}
              >
                {option}
              </Button>
            ))}
          </div>
        </div>
      )}
      <Typography variant="h6" gutterBottom>
        Your Matched Reptiles
      </Typography>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Reptile 1
            </Typography>
          </Grid>
      </Grid>
    </div>
  );
};

export default Quiz;
