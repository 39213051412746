import api from './api';

export const getEnclosures = async () => {
  try {
    const response = await api.get('/enclosure-parts/enclosures');
    if (!response.ok) {
      throw new Error('Failed to fetch enclosures');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching enclosures: ${error.message}`);
  }
}

export const getPlants = async () => {
  try {
    const response = await api.get('/enclosure-parts/plants');
    if (!response.ok) {
      throw new Error('Failed to fetch plants');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching plants: ${error.message}`);
  }
}

export const getSubstrates = async () => {
  try {
    const response = await api.get('/enclosure-parts/substrate');
    if (!response.ok) {
      throw new Error('Failed to fetch substrate');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching substrate: ${error.message}`);
  }
}

export const getLights = async () => {
  try {
    const response = await api.get('/enclosure-parts/lights');
    if (!response.ok) {
      throw new Error('Failed to fetch lights');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching lights: ${error.message}`);
  }
}

export const getWater = async () => {
  try {
    const response = await api.get('/enclosure-parts/water');
    if (!response.ok) {
      throw new Error('Failed to fetch water');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching water: ${error.message}`);
  }
}

export const getMaterials = async () => {
  try {
    const response = await api.get('/enclosure-parts/materials');
    if (!response.ok) {
      throw new Error('Failed to fetch materials');
    }
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching materials: ${error.message}`);
  }
}
