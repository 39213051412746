import api from './api';

export const createUser = async (username, email, password) => {
  try {
    const response = await api.post('/signup', {
      username,
      email,
      password
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const signInUser = async (username, password) => {
  try {
    const response = await api.post('/signin', {
      username,
      password
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};