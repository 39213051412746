import React from 'react';
import Profile from '../components/profile/Profile';

const ProfileView = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default ProfileView;
