import React from 'react';
import EnclosureBuilder from '../components/build/EnclosureBuilder.jsx';

const BuildView = () => {
  return (
    <div>
      <EnclosureBuilder />
    </div>
  );
};

export default BuildView;