import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { getProfileByUserId } from '../../services/profileService';
import { Container, Grid, Avatar, Typography, Card, CardContent, CardMedia, CircularProgress } from '@mui/material';

// Dummy data
const userData = {
  profileImage: 'https://via.placeholder.com/150',
  username: 'ReptiLover123',
  personalReptile: {
    name: 'Spike',
    species: 'Bearded Dragon',
    image: 'https://via.placeholder.com/150',
  },
  savedReptiles: [
    { commonName: 'Leopard Gecko', scientificName: 'Eublepharis macularius', image: 'https://via.placeholder.com/150' },
    { commonName: 'Ball Python', scientificName: 'Python regius', image: 'https://via.placeholder.com/150' },
    { commonName: 'Corn Snake', scientificName: 'Pantherophis guttatus', image: 'https://via.placeholder.com/150' },
  ],
  reptileQuizResult: { commonName: 'Bearded Dragon', scientificName: 'Pogona vitticeps', image: 'https://via.placeholder.com/150' }
};

const Profile = () => {
  const {
    profileImage,
    username,
    personalReptile,
    savedReptiles,
    reptileQuizResult,
  } = userData;
  const { currentUser } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);

      try {
        const idToken = await currentUser.getIdToken();
        const response = await getProfileByUserId(idToken);
        setProfileData(response);
      } catch (error) {
        setError('An error occurred while fetching profile data.');
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchProfileData();
    }
  }, [currentUser]);

  if (loading) return <CircularProgress />;

  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Avatar
            alt={username}
            src={profileImage}
            sx={{ width: 150, height: 150, marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h4">{profileData.displayName}</Typography>
        </Grid>

        {/* Personal Reptile Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Personal Reptile
          </Typography>
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={personalReptile.image}
              alt={personalReptile.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {personalReptile.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {personalReptile.species}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Saved Reptiles
          </Typography>
          <Grid container spacing={2}>
            {savedReptiles.map((reptile, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={reptile.image}
                    alt={reptile.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {reptile.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {reptile.species}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {reptileQuizResult && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Reptile Quiz Result
            </Typography>
            <Card>
              <CardMedia component="img" height="140" image={reptileQuizResult.image} alt={reptileQuizResult.commonName} />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {reptileQuizResult.commonName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {reptileQuizResult.scientificName}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Profile;