import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EnclosureGuide from '../components/enclosures/EnclosureGuide';

const GuideView = () => {
  return (
    <>
      <Routes>
        <Route path="/:id" element={<EnclosureGuide />} />
      </Routes>
    </>
  );
};

export default GuideView;
