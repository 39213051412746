import React from 'react';

export const EnclosureItem = ({ item }) => {
  return (
    <div>
      <h3>{item.name}</h3>
      <p>Type: {item.type}</p>
      <p>Price: ${item.price}</p>
    </div>
  );
};