import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText } from '@mui/material';

export const EnclosureItemModal = ({ isOpen, onClose, category, items, addToParts, loading }) => {
  const handleAddToParts = (item) => {
    addToParts(category, item);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {`${category}`}
        </Typography>
        {loading ? <Typography variant="h6">Loading...</Typography> :
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {items.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.name} secondary={`Type: ${item.type}`} />
                <button onClick={() => handleAddToParts(item)}>Add to Parts</button>
              </ListItem>
            ))}
          </List>
        }
      </Box>
    </Modal>
  );
};