import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import './banner.scss';

const Banner = () => {
  return (
    <Paper className="topBanner" elevation={3} sx={{ borderRadius: 0 }}>
      <div className="leftSide">
        <div className='container'>
          <h2>I WANT TO BUILD...</h2>
          <Button className='bannerButton' variant="contained" color="primary" component={Link} to="/build">
            AN ENCLOSURE
          </Button>
        </div>
      </div>
      <div className="rightSide">
        <div className='container'>
          <h2>I WANT TO FIND...</h2>
          <Button className='bannerButton' variant="contained" color="primary" component={Link} to="/quiz">
            A REPTILE
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default Banner;
