import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography, Card, CardContent, CardMedia, Grid, Container, Box } from '@mui/material';
import { getGuideByType } from '../../services/guideService';

const EnclosureGuide = () => {
  const { id } = useParams();
  const [guide, setGuide] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEnclosure = async () => {
      try {
        const response = await getGuideByType(id);
        setGuide(response[0]);
        console.log(response[0]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchEnclosure();
  }, [id]);

  if (loading) return (
    <Container style={{ textAlign: 'center', width: '100%', marginTop: '50px' }}>
      <CircularProgress />
    </Container>
  );

  return (
    <Container className='enclosureGuide'>
      {guide && (
        <>
          <Typography variant="h4" gutterBottom>
            {guide.title}
          </Typography>
          <Card>
            <CardMedia
              component="img"
              image={guide.image}
              alt={guide.title}
              style={{ height: 400 }}
            />
            <CardContent>
              <Typography variant="body1">{guide.description}</Typography>
            </CardContent>
          </Card>
          <Box mt={4}>
            {guide.steps && guide.steps.map((step, index) => (
              <Card key={index} style={{ marginBottom: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <CardMedia
                      component="img"
                      image={step.image}
                      alt={`Step ${index + 1}`}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <CardContent>
                      <Typography variant="h5">Step {index + 1}</Typography>
                      <Typography variant="body1">{step.title}</Typography>
                      <Typography variant="body2">{step.description}</Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Box>
        </>
      )}
    </Container>
  );
}

export default EnclosureGuide;