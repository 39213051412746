import React from 'react';
import { Grid } from '@mui/material';

const NotFound = () => {
  return (
    <Grid sx={{ textAlign: 'center' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </Grid>
  );
};

export default NotFound;
